<template>
  <a-select
    :allow-clear="false"
    :disabled="disabled"
    :filter-option="false"
    :options="options"
    placeholder="请选择方式"
    :show-arrow="true"
    :value="value"
    @change="v => $emit('input', v)"
  ></a-select>
</template>

<script>
export default {
  name: 'MethodSelect',
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: String }
  },
  data () {
    return {
      options: [
        { title: this.$t('notification_method.email'), value: 'email' },
        { title: this.$t('notification_method.phone'), value: 'phone' },
        { title: this.$t('notification_method.dingtalk'), value: 'dingtalk' },
        { title: this.$t('notification_method.wecom'), value: 'wecom' }
      ]
    }
  }
}
</script>
