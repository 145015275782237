<template>
  <a-card :bordered="false">
    <a-row>
      <a-col :sm="24" :xl="18" :xxl="12">
        <a-form-model ref="form" :label-col="labelCol" :model="form" :rules="rules" :wrapper-col="wrapperCol">
          <a-form-model-item label="策略名称" prop="name">
            <a-input v-model="form.name" :max-length="128" placeholder="请输入策略名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="严重性" prop="severities">
            <severity-select v-model="form.severities" :allow-clear="false" mode="multiple"></severity-select>
          </a-form-model-item>

          <a-divider orientation="left">触发规则</a-divider>
          <a-card
            v-for="(trigger, index) in form.triggers"
            :key="`trigger_${index}`"
            :bordered="false"
            size="small"
            :title="`规则 ${index + 1}`"
          >
            <a-button
              v-if="form.triggers.length > 1"
              slot="extra"
              icon="delete"
              size="small"
              type="danger"
              @click="form.triggers.splice(index, 1)"
            >
              删除
            </a-button>

            <a-form-model-item
              label="事件源"
              :label-col="labelCol"
              :prop="`triggers[${index}].source_type`"
              :rules="[{ message: '请选择告警事件源', required: true, trigger: 'change' }]"
              :wrapper-col="wrapperCol"
            >
              <source-type-select v-model="trigger.source_type" :selected="form.triggers.map(t => t.source_type)"></source-type-select>
            </a-form-model-item>
            <a-form-model-item
              label="触发条件"
              :label-col="labelCol"
              :prop="`triggers[${index}].condition`"
              :rules="[{ message: '请选择触发条件', required: true, trigger: 'change' }]"
              :wrapper-col="wrapperCol"
            >
              <alert-strategy-condition-select v-model="trigger.condition"></alert-strategy-condition-select>
            </a-form-model-item>
            <template v-if="trigger.source_type">
              <a-form-model-item
                :label="$t(`source_type.${trigger.source_type}`)"
                :label-col="labelCol"
                :prop="`triggers[${index}].source_ids`"
                :rules="[{ message: '请选择' + $t(`source_type.${trigger.source_type}`), required: true, trigger: 'change' }]"
                :wrapper-col="wrapperCol"
              >
                <source-select v-model="trigger.source_ids" mode="multiple" :source-type="trigger.source_type"></source-select>
              </a-form-model-item>
            </template>
          </a-card>
          <div style="margin-bottom: 16px;">
            <a-button v-if="form.triggers.length < 5" :block="true" icon="plus" type="dashed" @click="form.triggers.push({})">添加规则</a-button>
          </div>

          <a-divider orientation="left">通知规则</a-divider>
          <a-card
            v-for="(notification, index) in form.notifications"
            :key="`notification_${index}`"
            :bordered="false"
            size="small"
            :title="`规则 ${index + 1}`"
          >
            <a-button
              v-if="form.notifications.length > 1"
              slot="extra"
              icon="delete"
              size="small"
              type="danger"
              @click="form.notifications.splice(index, 1)"
            >
              删除
            </a-button>

            <a-form-model-item
              label="方式"
              :label-col="labelCol"
              :prop="`notifications[${index}].method`"
              :rules="[{ message: '请选择通知方式', required: true, trigger: 'change' }]"
              :wrapper-col="wrapperCol"
            >
              <alert-notification-method-select v-model="notification.method"></alert-notification-method-select>
            </a-form-model-item>
            <a-form-model-item
              v-if="notification.method && notification.method !== 'email'"
              label="Webhook"
              :label-col="labelCol"
              :prop="`notifications[${index}].webhook`"
              :rules="[
                { message: '请输入 Webhook', required: true, trigger: 'blur' },
                { message: '请输入正确的 URL', type: 'url', trigger: 'blur' }
              ]"
              :wrapper-col="wrapperCol"
            >
              <a-input v-model="notification.webhook" :max-length="128"></a-input>
            </a-form-model-item>
            <a-form-model-item
              label="用户"
              :label-col="labelCol"
              :prop="`notifications[${index}].user_ids`"
              :rules="[{ message: '请选择用户', required: true, trigger: 'change' }]"
              :wrapper-col="wrapperCol"
            >
              <user-select v-model="notification.user_ids" mode="multiple"></user-select>
            </a-form-model-item>
          </a-card>
          <div style="margin-bottom: 16px;">
            <a-button :block="true" icon="plus" type="dashed" @click="form.notifications.push({})">添加规则</a-button>
          </div>
          <a-space style="user-select: none;">
            <a-button :loading="loading" type="primary" @click="click">创建</a-button>
            <a-button @click="$router.push({ name: 'AlertStrategyList' })">返回</a-button>
          </a-space>
        </a-form-model>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { createAlertStrategy } from '@/api/alert-strategy'
import AlertNotificationMethodSelect from '@/components/select/AlertNotificationMethodSelect'
import AlertStrategyConditionSelect from '@/components/select/AlertStrategyConditionSelect'
import SeveritySelect from '@/components/select/SeveritySelect'
import SourceSelect from '@/components/select/SourceSelect'
import UserSelect from '@/components/select/UserSelect'
import SourceTypeSelect from './modules/SourceTypeSelect'

export default {
  name: 'AlertStrategyCreate',
  components: {
    AlertNotificationMethodSelect,
    AlertStrategyConditionSelect,
    SeveritySelect,
    SourceSelect,
    SourceTypeSelect,
    UserSelect
  },
  data () {
    return {
      form: {
        triggers: [{
          source_type: 'host',
          condition: 'in_list',
          source_ids: []
        }],
        notifications: [{ method: 'email', user_ids: [] }]
      },
      labelCol: { span: 4, style: { textAlign: 'left' } },
      wrapperCol: { span: 20 },
      rules: {
        name: [{ message: '请输入策略名称', required: true, trigger: 'blur' }],
        severities: [{ message: '请选择告警严重性', required: true, trigger: 'change' }]
      },
      loading: false
    }
  },
  methods: {
    click () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          createAlertStrategy(this.form).then(res => {
            this.$message.success(res.message)
            this.$refs.form.resetFields()
            this.$router.push({ name: 'AlertStrategyList' })
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
