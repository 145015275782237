<template>
  <a-select
    :allow-clear="false"
    :filter-option="false"
    :options="options"
    placeholder="请选择条件"
    :show-arrow="true"
    :value="value"
    @change="v => $emit('input', v)"
  ></a-select>
</template>

<script>
export default {
  name: 'AlertStrategyConditionSelect',
  props: {
    value: { type: String }
  },
  data () {
    return {
      options: [
        { title: this.$t('trigger_condition.in_list'), value: 'in_list' },
        { title: this.$t('trigger_condition.not_in_list'), value: 'not_in_list' }
      ]
    }
  }
}
</script>
