<template>
  <a-select
    :allow-clear="false"
    :filter-option="false"
    :options="options"
    placeholder="请选择告警事件源"
    :show-arrow="true"
    :value="value"
    @change="v => $emit('input', v)"
  ></a-select>
</template>

<script>
import { sourceTypes } from '@/utils'

export default {
  name: 'TargetSelect',
  props: {
    value: { type: String },
    selected: { type: Array, default: () => [] }
  },
  computed: {
    options () {
      const constantOptions = sourceTypes()
      return constantOptions.filter(option => this.value === option.value || this.selected.indexOf(option.value) === -1)
    }
  }
}
</script>
